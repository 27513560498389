<template>
  <v-sheet id="participants">
    <loading class="mt-8 ml-8" v-if="!event || usersAreLoading" />

    <v-container v-else :class="{ 'menu-open': displayMenu }">
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="title mt-5 mb-2">{{ $t('participants.title') }}</h1>
        </v-col>
      </v-row>

      <v-row no-gutters class="align-center">
        <v-col cols="12" lg="7" class="mb-4">
          <div class="filter">
            <v-radio-group
              class="filter__item"
              name="ticket-type"
              row
              :value="eventUserFilterTicketType"
              @change="updateTicketTypeFilter"
              hide-details
            >
              <v-radio
                v-for="ticketType in getTicketTypes()"
                :key="`filter-ticket-type-${ticketType}`"
                :label="$t(`admin.tickets.type.${ticketType}`)"
                :value="ticketType"
              />
            </v-radio-group>
          </div>
        </v-col>

        <v-col cols="12" lg="3" class="mb-4">
          <div class="search">
            <AppSearch class="search__item" @change="onSearchChange" />
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="d-flex flex-wrap">
          <relation
            class="participant flex"
            v-for="relation in limitedEventUsers"
            :key="relation.id"
            :relation="relation"
            :isContact="isContact(relation)"
            :showCalendar="getUserHasAppointment(relation.id)"
            :loggedUserId="loggedInUser.id"
            :userClickable="true"
            @add-connection="addConnection"
            @remove-connection="removeConnection"
            @go-to-profile="openProfile"
            @chat="openChat"
            @booking="booking"
            @click="openProfile"
          />

          <div v-if="!hasUsers">
            <p class="title my-5">{{ $t('participants.empty') }}</p>
          </div>

          <div v-if="!hasFilteredUsers && hasUsers">
            <p class="title my-5">{{ $t('participants.emptyFilter') }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <user-info
      v-if="displayMenu"
      :user="selectedUser"
      :menu-open="displayMenu"
      :event-id="event.id"
      @change="toggleDisplayMenu"
      @close-menu="closeProfile"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Relation from '@/components/relation/Relation.vue';
import Loading from '@/components/loading/Loading.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import UserInfo from '@/components/user-info/UserInfo.vue';

import { TicketType } from '@/models/ticketing/ticket-type.model';
import { toUserBooking } from '@/navigation';

import {
  ADD_TO_CONTACTS,
  GET_RELATIONS,
  RELATIONS_MODULE,
  REMOVE_FROM_CONTACTS,
} from '@/stores/umanize-app/actions/relations/relations.actions';
import {
  APPOINTMENT_MODULE,
  CHECK_USERS_HAVE_APPOINTMENT,
} from '@/stores/umanize-app/actions/appointment/appointment.action';
import {
  APP_EVENT_MODULE,
  UPDATE_EVENT_FILTER,
} from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_CHATS_MODULE, OPEN_CHAT } from '@/stores/umanize-app/actions/chats/chats.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

export default {
  name: 'Participants',
  components: {
    Relation,
    Loading,
    AppSearch,
    UserInfo,
  },
  data: () => ({
    MAX_ITEMS: 18,
    currentOffset: 0,
    searchText: '',
    selectedUser: null,
    displayMenu: false,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, [
      'event',
      'eventUsers',
      'eventUsersFilter',
      'eventUserFilterTicketType',
      'filteredEventUsers',
      'usersAreLoading',
    ]),
    ...mapGetters(RELATIONS_MODULE, ['isConnected']),
    ...mapGetters(APPOINTMENT_MODULE, ['getUserHasAppointment']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    hasUsers() {
      return this.eventUsers.length > 0;
    },
    hasFilteredUsers() {
      return this.filteredEventUsers.length > 0;
    },
    limitedEventUsers() {
      return [...this.filteredEventUsers].splice(
        0,
        this.currentOffset * this.MAX_ITEMS + this.MAX_ITEMS,
      );
    },
  },
  methods: {
    ...mapActions(APP_EVENT_MODULE, [FETCH_EVENT_USERS, UPDATE_EVENT_FILTER]),
    ...mapActions(APP_CHATS_MODULE, [OPEN_CHAT]),
    ...mapActions(RELATIONS_MODULE, [GET_RELATIONS, ADD_TO_CONTACTS, REMOVE_FROM_CONTACTS]),
    ...mapActions(APPOINTMENT_MODULE, [CHECK_USERS_HAVE_APPOINTMENT]),
    onSearchChange(search) {
      this.searchText = search;
      this.search();
    },
    toggleDisplayMenu(value) {
      if (this.displayMenu !== value) {
        this.displayMenu = value;
      }
    },
    openProfile(user) {
      this.selectedUser = user;
      this.displayMenu = true;
    },
    closeProfile() {
      this.selectedUser = null;
      this.displayMenu = false;
    },
    isContact(relation) {
      return this.isConnected(relation.id);
    },
    addConnection(relation) {
      this[ADD_TO_CONTACTS](relation);
    },
    removeConnection(relation) {
      this[REMOVE_FROM_CONTACTS](relation);
    },
    openChat(relation) {
      this[OPEN_CHAT]({ userIds: [relation.id] });
    },
    getTicketTypes() {
      return [
        TicketType.all,
        TicketType.visitor,
        TicketType.exhibitor,
        TicketType.speaker,
        TicketType.organizer,
      ];
    },
    search() {
      this[UPDATE_EVENT_FILTER]({
        ...this.eventUsersFilter,
        searchText: this.searchText,
      });
    },
    updateTicketTypeFilter(ticketType) {
      this[UPDATE_EVENT_FILTER]({
        ...this.eventUsersFilter,
        ticketType,
      });
    },
    booking(relation) {
      toUserBooking(this.$router)({
        eventId: this.event.id,
        userId: relation.id,
      });
    },
    scroll() {
      // Infinite scroll method, if the bottom of the page is reached, loads the next elements
      window.onscroll = () => {
        const bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop,
          ) +
            300 +
            window.innerHeight >=
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          this.currentOffset += 1;
        }
      };
    },
  },
  async mounted() {
    await Promise.all([
      this[CHECK_USERS_HAVE_APPOINTMENT]({
        eventId: this.event.id,
      }),
      this[GET_RELATIONS](),
      this[FETCH_EVENT_USERS]({ id: this.event.id, isActive: true }),
    ]);
    this.scroll();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.filter {
  &__item {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

.search {
  width: 80%;
}

.participants {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
}

.participant {
  box-sizing: border-box;
  width: 100%;
  background-color: var(--v-primaryBackground-base);
  border-radius: 0.5rem;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 30px 30px 0;
}

#participants .theme--light.v-sheet {
  background-color: transparent;
}

@include breakpoint(small) {
  .menu-open {
    padding-left: 40vw;
  }

  .participant {
    width: 45%;
  }
}

@include breakpoint(medium) {
  .menu-open {
    padding-left: 10vw;
  }

  .participant {
    width: 30%;
  }

  .search {
    width: 100%;
  }
}
</style>
